<template>
  <div>
    <transition name="flixFadeIn">
      <div :key="check">
        <div class="flix-pull-right">
          <a href="#" @click.prevent="check = !check" class="flix-html-a flix-text-danger">
            <flixIcon :id="'bin'" style="margin-right: 4px"/>
          </a>
          <span v-if="check" class="flix-pull-right">
            <span class="flix-html-strong flix-text-danger">{{ $t('message.delete') }}?</span><br><a href="#" class="flix-html-a" @click.prevent="check = !check">{{ $t('message.no') }}</a> | <a href="#" class="flix-html-a" @click.prevent="setDelete()">{{ $t('message.yes') }}</a>
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    setDelete: Function
  },
  data () {
    return {
      check: false
    }
  },
  methods: {
    checkDelete () {
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
